<template>
  <div>
    <a-form-model ref="ruleForm" :model="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }">
      <h2>查看</h2>
      <h3 style="margin-top: 15px">基础信息</h3>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="线路名称" prop="linename"> {{ form.linename }} </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="运营时间" prop="operationtime"> {{ form.operationtime }} </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="首班车时间" prop="firstbustime"> {{ form.firstbustime }} </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="末班车时间" prop="endbustime"> {{ form.endbustime }} </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="透出项目" prop="giveProject"> {{ form.projectList }} </a-form-model-item>
        </a-col>
        <a-col :span="12">
          <a-form-model-item label="状态" prop="busState">
            {{ form.busState === 0 ? "启用" : "禁用" }}
          </a-form-model-item>
        </a-col>
      </a-row>
      <h3 style="margin-top: 15px">班车时刻表</h3>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="详情图集" prop="lineImage">
            <el-image class="imgListImg" :src="form.lineImage" :preview-src-list="imgList"> </el-image>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="12">
          <a-form-model-item label="班车通行方向" prop="region">
            {{ form.singleordoubleline === '1' ? "单向 （即始发站到终点站）" : "双向" }}
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row v-for="(item, index) in form.siteInfo">
        <a-col :span="1"> </a-col>
        <a-col :span="6">
          <a-form-model-item
            :label="index === 0 ? '始发站' : index === form.siteInfo.length - 1 ? '终点站' : '站点名称'"
          >
            {{ item.sitename }}
          </a-form-model-item>
        </a-col>
        <a-col :span="7">
          <a-form-model-item label="经纬度"> {{ item.sitelongitude}}, {{ item.sitelatitude }}</a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item label="标签">
            {{ item.des }}
            <colorPicker style="margin-left: 10px" disabled v-model="color1" />
          </a-form-model-item>
        </a-col>
        <a-col :span="5">
          <a-form-model-item label="标签">
            {{ item.desTwo }}
            <colorPicker style="margin-left: 10px" disabled v-model="color2" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <h3 style="margin-top: 15px">线路轨迹信息</h3>
      <a-row>
        <a-col :span="24">
          <a-form-model-item class="lableWidth" label="终端设备/轨迹" prop="terminalAndTrajectory">
            {{ form.terminalAndTrajectory }}
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <a-form-model-item class="lableWidth" label="班车线路图" prop="lineinfojson">
            {{ form.lineinfojson }}
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-form-model-item :wrapper-col="{ span: 6, offset: 10 }">
        <a-button style="margin-left: 10px" @click="handleCancel"> 返回 </a-button>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import Vue from "vue";
import { Image } from "element-ui";
import * as api from "@/api/userMessage";
Vue.use(Image);
export default {
  data() {
    return {
      color1: "#70B603",
      color2: "#F59A23",
      labelCol: { span: 7 },
      wrapperCol: { span: 15 },
      form: {},
      title: "查看",
      id: "",
      imgList: [],
    };
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getDetail();
  },
  methods: {
    async getDetail() {
      const res = await api.getBusById({ id: this.id });
      if (res.code === "200") {
        if (res.data.projectList) {
          res.data.projectList = res.data.projectList.join(",");
        }
        this.form = res.data;
        this.imgList.push(res.data.lineImage);
      }
    },
    //取消
    handleCancel() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.imgListImg {
  width: 100px;
  height: 100px;
  margin-left: 10px;
}
.ant-form-item {
  margin-bottom: 0px;
}
.upload_tip {
  line-height: 20px;
  font-size: 12px;
}
::v-deep .lableWidth .ant-col-6 {
  width: 10.5%;
}
::v-deep .lable1Width .ant-col-6 {
  width: 12.5%;
}
::v-deep .lableWidth .ant-col-15 {
  width: 81%;
}
::v-deep .ant-form-item-children{
  word-break: break-all;
}
</style>